import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import styles from '../pages/styles.module.css'; // Adjust the path as needed

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 18.748,
  lng: 73.749
};

const MapComponent = () => {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCKHB5TacFk8ACQXB05Z01RgUPgmL9VTGs"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
