// src/components/Layout.js
import React from 'react';
import Footer from '../components/footer'; // Adjust the path as needed

const Layout = ({ children }) => {
  return (
    <div>
      <header>
        {/* Add your header content here */}
      </header>
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
