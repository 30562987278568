import React, { useLayoutEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Career from './pages/Career';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Enquiry from './pages/Enquiry';
import VendersArea from './pages/VendersArea';
import Signup from './pages/Signup';
import VendersDashboard from './pages/VendersDashboard';
import NavigationBar from './pages/NavigationBar'; // Corrected import path
import './pages/i18n';


function App() {
  
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#F1F1F1";
  }, []);

  return (
    <BrowserRouter>
      <NavigationBar />
      <Layout>
        <Routes basename="/Rekha1908/dairywebsite">
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products" element={<Products />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/vendersarea" element={<VendersArea />} /> 
          <Route path="/signup" element={<Signup />} /> 
          <Route path="/vendersDashboard" element={<VendersDashboard/>}/>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
