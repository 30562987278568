import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from '../pages/styles.module.css';

const testimonials = [
  {
    id: 1,
    name: 'Jitendra Sandbhor',
    text: 'Excellent quality milk. My family loves it!',
  },
  {
    id: 2,
    name: 'Santosh Bute',
    text: 'Great service and delicious products!',
  },
  {
    id: 3,
    name: 'Unknown User',
    text: 'The best dairy products in the market!',
  },
  // Add more testimonials as needed
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Testimonials = () => {
  return (
    <Carousel 
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      className={styles.testimonialsCarousel}
    >
      {testimonials.map((testimonial) => (
        <div key={testimonial.id} className={styles.testimonialCard}>
          <p>"{testimonial.text}"</p>
          <footer className="blockquote-footer">{testimonial.name}</footer>
        </div>
      ))}
    </Carousel>
  );
};

export default Testimonials;
