// src/components/NavigationBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import styles from "./styles.module.css";
import logo from '../asset/Image/krushilogo.png'; // Make sure to update the path to your actual logo image

function NavigationBar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  };

  return (
    <nav className={styles.navStyle}>
      <div className={styles.logoContainer}>
        <Link to="/">
          <img src={logo} alt="Logo" className={styles.logoStyle} />
        </Link>
      </div>
      <ul className={styles.ulStyle}>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/">
            {t('Home')}
          </Link>
        </li>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/about">
            {t('About')}
          </Link>
        </li>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/contact">
            {t('Contact')}
          </Link>
        </li>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/products">
            {t('Products')}
          </Link>
        </li>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/enquiry">
            {t('Enquiry')}
          </Link>
        </li>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/careers">
            {t('Careers')}
          </Link>
        </li>
        <li className={styles.liStyle}>
          <Link className={styles.linkStyle} to="/VendersArea">
            {t('VendersArea')}
          </Link>
        </li>
        
        <li className={styles.liStyle}>
          <BsFillTelephoneFill className={styles.iconStyle} />
          <span className={styles.linkStyle}>{t('+91-7888160160')}</span>
        </li>
      </ul>
      <div className={styles.languageSwitch}>
        <button className={styles.languageButton} onClick={() => changeLanguage('en')}>
          English
        </button>
        <button className={styles.languageButton} onClick={() => changeLanguage('mr')}>
          मराठी
        </button>
      </div>
    </nav>
  );
}

export default NavigationBar;
