// src/components/VisionMission.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import styles from '../pages/styles.module.css'; // Adjust the path as needed

const VisionMission = () => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <Card>
          <Card.Body>
            <Card.Title>Our Vision</Card.Title>
            <Card.Text>
              To be a leading provider of premium dairy products that enhance the daily lives of our customers with purity, quality, and authentic taste.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className={styles.card}>
        <Card>
          <Card.Body>
            <Card.Title>Our Mission</Card.Title>
            <Card.Text>
              Our journey began in 2018 with a firm belief in the significance of milk in every individual's life. What started as an extension of our family business has evolved into a mission dedicated to purity and quality. We take great pride in offering a wide range of dairy products, including dahi, taak, cow's milk, buffalo milk, and ghee, all crafted to deliver the authentic taste you deserve. Our commitment is to enrich your daily life with the best dairy products, ensuring each product is safe, nutritious, and delicious.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default VisionMission;
