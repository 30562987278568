import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/style.enquiryform.css'; // Import custom CSS for additional styling

function Enquiry() {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    company: '',
    city: '',
    email: '',
    mobile: '',
    enquiry: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000); // Hide the success message after 5 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component is unmounted or if successMessage changes
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleOnFormComplete();
  };

  const handleOnFormComplete = async () => {
    try {
      const response = await fetch('https://api.krushiratnamilk.com/api/enquiries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)
      });

      if (!response.ok) {
        throw new Error('Failed to submit enquiry');
      }

      const data = await response.json();

      setSuccessMessage('Enquiry submitted successfully!');
      setErrorMessage('');
      setForm({
        firstName: '',
        lastName: '',
        company: '',
        city: '',
        email: '',
        mobile: '',
  
        enquiry: ''
      });
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      setErrorMessage('Error submitting enquiry. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <Card className="shadow-lg p-4 enquiry-card">
            <Card.Body>
              <h1 className="text-center mb-4">Enquiry Form</h1>
              <p className="text-center mb-4">
                We are looking forward to understanding your needs and partnering with you. Please fill the following form. We will get in touch with you!
              </p>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFirstName" className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleChange}
                        placeholder="Enter your full name"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formMobile" className="mb-3">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="text"
                        name="mobile"
                        value={form.mobile}
                        onChange={handleChange}
                        placeholder="Enter your mobile number"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFirstName" className="mb-3">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleChange}
                        placeholder="Enter your E-mail"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="address" className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="Address"
                        value={form.Address}
                        onChange={handleChange}
                        placeholder="Enter your Address"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formEnquiry" className="mb-3">
                  <Form.Label>Equiry</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="E-mail" 
                    onChange={handleChange}
                    placeholder="Enter your Equiry"
                    rows={4}
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Enquiry;
