import React from 'react';
import OrderForm from './OrderForm';

export default function VendersDashboard() {
  return (
    <div>Welcome to VendersDashboard !!!!!!!!!!



      <OrderForm/>
    </div>
  )
}
