import React, { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import VisionMission from '../components/visionmission';
import 'bootstrap/dist/css/bootstrap.min.css';
import Styles from '../pages/styles.module.css';

const OurTeam = lazy(() => import('../components/OurTeam'));

function About() {
  const { t } = useTranslation();
  
  return (
    <div className={Styles.appContainer}>
      
          <VisionMission/>
        
        <Suspense fallback={<div>Loading...</div>}>
          <section className={Styles.teamSection}>
            <Container>
              <h2 className={Styles.sectionHeading}>{t('Meet Our Team')}</h2>
              <OurTeam />
            </Container>
          </section>
        </Suspense>
      </div>
    
  );
}

export default About;
