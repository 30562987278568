import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Alert } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/style.enquiryform.css';

const Signup = () => {
    const [form, setForm] = useState({
        username: '',
        password: '',
        email: '',
        contact: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const response = await axios.post('https://api.krushiratnamilk.com/signup', form);
            console.log('Signup successful:', response.data);
            setSuccessMessage('Signup successful!');
            setErrorMessage(''); // Reset error message on success
        } catch (error) {
            console.error('Signup failed:', error.response ? error.response.data : error.message);
            setErrorMessage(error.response ? error.response.data : 'Signup failed. Please try again.');
            setSuccessMessage(''); // Reset success message on failure
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={8}>
                    <Card className="shadow-lg p-4 enquiry-card">
                        <Card.Body>
                            <h1 className="text-center mb-4">Welcome New Vendor Signup</h1>
                            <p className="text-center mb-4">Fill in the required information below</p>
                            {successMessage && <Alert variant="success">{successMessage}</Alert>}
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formUsername" className="mb-3">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="username"
                                                value={form.username}
                                                onChange={handleChange}
                                                placeholder="Enter your username"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="formMobile" className="mb-3">
                                            <Form.Label>Contact</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="contact"
                                                value={form.contact}
                                                onChange={handleChange}
                                                placeholder="Enter your mobile number"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formEmail" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={form.email}
                                                onChange={handleChange}
                                                placeholder="Enter your email"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="formPassword" className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={form.password}
                                                onChange={handleChange}
                                                placeholder="Enter your password"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" className="w-100">
                                    Submit
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Signup;
