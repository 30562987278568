import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import MapComponent from '../components/MapComponent';
import Styles from './styles.module.css';
import plantImage from '../asset/Image/plant.jpg';

function Contact() {
  const { t } = useTranslation();

  return (
    <div className={Styles.homeContainer}>
      <div className={Styles.backgroundImage}>
        <div className={Styles.overlay}></div>
        <div className={Styles.contactContainer}>
          <div className={Styles.contactAppContainer}>
            <div className={Styles.contactText}>
              <div className={Styles.contactSection}>
                <h1 className={Styles.contactHeader}>
                  <FontAwesomeIcon icon={faHome} className={Styles.icon} /> {t('Address')}
                </h1>
                <p className={Styles.contactInfo}>{t('Gat No-66, Bhamboli, Khed, Pune, Maharashtra - 410505')}</p>
                <div className={Styles.mapContainer}>
                  <MapComponent/>
                  {/* <iframe
                    src="https://maps.app.goo.gl/HX6H3JaipFwZPak48"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Map"
                  ></iframe> */}
                </div>
              </div>
              <div className={Styles.contactSection}>
                <h1 className={Styles.contactHeader}>
                  <FontAwesomeIcon icon={faPhone} className={Styles.icon} /> {t('Contact us on')}
                </h1>
                <p className={Styles.contactInfo}>{t('+91-7888160160')}</p>
              </div>
              <div className={Styles.contactSection}>
                <h1 className={Styles.contactHeader}>
                  <FontAwesomeIcon icon={faEnvelope} className={Styles.icon} /> {t('Write us on')}
                </h1>
                <p className={Styles.contactInfo}>
                  <a href="mailto:bhamchanramilk@gmail.com" className={Styles.emailLink}>
                    {t('bhamchanramilk@gmail.com')}
                  </a>
                </p>
              </div>
            </div>
            <div className={Styles.imageContainerContact}>
              <img className={Styles.imageContact} src={plantImage} alt="Background" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
