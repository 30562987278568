import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making HTTP requests
import 'bootstrap/dist/css/bootstrap.min.css';


const OrderForm = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        productName: '',
        quantity: '',
        price: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        console.log('Submitting order form:', form);

        try {
            const response = await axios.post('http://api.krushiratnamilk.com/order', {
                productName: form.productName,
                quantity: form.quantity,
                price: form.price
            });

            console.log('Order Response:', response);

            if (response.status === 201) {
                setSuccessMessage('Order placed successfully!');
                setErrorMessage('');
                navigate('/orderConfirmation'); // Redirect to an order confirmation page
            } else {
                setSuccessMessage('');
                setErrorMessage('Failed to place order. Please try again.');
            }
        } catch (error) {
            console.error('Order Error:', error);
            setSuccessMessage('');
            setErrorMessage('Failed to place order. Please try again.');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={8}>
                    <Card className="shadow-lg p-4 order-card">
                        <Card.Body>
                            <h1 className="text-center mb-4">Place Your Order</h1>
                            {successMessage && <Alert variant="success">{successMessage}</Alert>}
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formProductName" className="mb-3">
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="productName"
                                        value={form.productName}
                                        onChange={handleChange}
                                        placeholder="Enter product name"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formQuantity" className="mb-3">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="quantity"
                                        value={form.quantity}
                                        onChange={handleChange}
                                        placeholder="Enter quantity"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPrice" className="mb-3">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="price"
                                        value={form.price}
                                        onChange={handleChange}
                                        placeholder="Enter price"
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100">
                                    Place Order
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default OrderForm;
