// src/components/Footer.js
import React from 'react';
import Styles from '../pages/styles.module.css';

function Footer() {
  return (
    <div className={Styles.footer}>
      <p className={Styles.footerText}>&copy; 2024 Your Company. All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
