// src/components/Home.js
import React from 'react';
import Styles from './styles.module.css';
import Footer from '../components/footer';
import { useTranslation } from 'react-i18next';

function Product() {
  const { t } = useTranslation();

  const imageFiles = [
    { img: 'Buffalo.jpg', title: t("Full Cream Milk"), sizes: " 500ml, 1000ml" },
    { img: 'ghee.png', title: t("Ghee"), sizes: "100ml,200ml, 500ml, 1000ml" },
    { img: 'cowmilk.png', title: t("Cow Milk"), sizes: "200ml, 500ml, 1000ml" },
    { img: 'curd.png', title: t("Curd"), sizes: "200ml, 500ml, 1KG,2KG" },
    { img: 'taza milk.png', title: t("Taza Milk"), sizes: "200ml, 500ml, 1000ml" },
    { img: 'buttermilk.png', title: t("Butter Milk"), sizes: "500ml" },
    { img: 'lassi.png', title: t("Lassi"), sizes: "200 ml" },
    { img: 'paneer.png', title: t("Paneer"), sizes: "500g, 1kg, 2kg" }
  ];

  return (
    <div className={Styles.appContainer}>
      <div className={Styles.container}>
        <div className={Styles.imagegrid}>
          {imageFiles.map((imageFile, index) => (
            <div key={index} className={Styles.productGrid}>
              <img className={Styles.imageContainer}
                src={require(`../asset/Image/${imageFile.img}`)}
                alt={`Krushiratnaimages ${index + 1}`}
              />
              <p className={Styles.imageText}>{imageFile.title}</p>
              <p className={Styles.sizeText}>{imageFile.sizes}</p>
            </div>
          ))}
        </div>
      </div>
  
    </div>
  );
}

export default Product;
