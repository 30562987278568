import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import styles from '../pages/styles.module.css'; // Adjust the path as needed

const Career = () => {
  return (
    <div className={styles.careerPage}>
      <h1 className={styles.header}>Join Our Team!</h1>
      <p className={styles.intro}>
        At Krushiratna Milk, we're always on the lookout for passionate and talented individuals to join our team. Explore our current openings and discover how you can contribute to our mission and grow with us.
      </p>
      <div className={styles.cardContainer}>
        <Card className={styles.cardCareer}>
          <Card.Body>
            <Card.Title className={styles.cardTitle}>Open Positions</Card.Title>
            <Card.Text className={styles.cardText}>
            <strong>Position:</strong> Accountant <br />
              <strong>Education:</strong> Graduate in any stream with mandatory Tally course completion<br />
              <strong>Location:</strong> Chakan,Pune, Maharastra<br />
              <strong>Experience:</strong> Required Experience: 2+ years in relevant field
            </Card.Text>
            <Button href="mailto:bhamchanramilk@gmail.com" variant="primary" className={styles.button}>Apply Now</Button>
            <div className={styles.contactInfo}>
              <p><strong>share your profile on:</strong></p>
              <p>Email: <a href="mailto:bhamchanramilk@gmail.com">bhamchanramilk@gmail.com</a></p>
              <p>Phone: <a href="tel:9860460995">9860460995</a></p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Career;
