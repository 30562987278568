import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Our journey began in 2018...": "Our journey began in 2018 with a firm belief in the significance of milk in every individual's life. What initially started as an extension of our family business has now become a mission dedicated to purity and quality. We take great pride in serving you with a wide range of dairy products, all crafted to deliver the authentic taste you deserve. Our offerings include dahi, taak, cow's milk, buffalo milk, and ghee, all aimed at enriching your daily life.",
      "Our Commitments": "Our Commitments",
      "At Krushiranta Milk we believe...": "At Krushiranta Milk we believe the happiest animals make for the tastiest foods, and we’re committed to sourcing all of our dairy products from the best-treated animals you can find. Anything else would be providing you with less than the best possible cheeses, kinds of milk, creams, and yoghurts. That simply wouldn’t do.",
      "Address": "Address",
      "Contact us on": "Contact us on",
      "Write us on": "Write us on",
      "Gat No-66, Bhamboli, Khed, Pune, Maharashtra - 410505": "Gat No-66, Bhamboli, Khed, Pune, Maharashtra - 410505",
      "7888160160": "7888160160",
      "bhamchanramilk@gmail.com": "bhamchanramilk@gmail.com",
      "Home": "Home",
      "About": "About",
      "Contact": "Contact",
      "Gallery": "Gallery",
      "Enquiry":"Enquiry",
      "Full Cream Milk": "Full Cream Milk",
      "Ghee": "Ghee",
      "Cow Milk": "Cow Milk",
      "Curd": "Curd",
      "Taza Milk": "Taza Milk",
      "Butter Milk": "Butter Milk",
      "Lassi": "Lassi",
      "Paneer": "Paneer",
      "+91-7888160160": "+91-7888160160"
    }
  },
  mr: {
    translation: {
      "Our journey began in 2018 with a firm belief in the significance of milk in every individual's life. What initially started as an extension of our family business has now become a mission dedicated to purity and quality. We take great pride in serving you with a wide range of dairy products, all crafted to deliver the authentic taste you deserve. Our offerings include dahi, taak, cow's milk, buffalo milk, and ghee, all aimed at enriching your daily life.": "२०१८ मध्ये प्रत्येक व्यक्तीच्या जीवनात दुधाचे महत्त्व यावर ठाम विश्वास ठेवून आमचा प्रवास सुरू झाला. सुरुवातीला आमच्या कौटुंबिक व्यवसायाचा विस्तार म्हणून जे सुरू झाले ते आता शुद्धता आणि गुणवत्तेला समर्पित मिशन बनले आहे. आम्हाला तुम्हाला विस्तृत डेअरी उत्पादने पुरवण्यात आम्हाला खूप अभिमान वाटतो, जे सर्व तुमच्या पात्रतेच्या अस्सल चव वितरीत करण्यासाठी तयार केले आहे. आमच्या ऑफरमध्ये दही, टाक, गाईचे दूध, म्हशीचे दूध आणि तूप यांचा समावेश आहे, ज्याचा उद्देश तुमचे दैनंदिन जीवन समृद्ध करणे आहे.",
      "Our Commitments": "आमची वचने",
      "At Krushiranta Milk we believe the happiest animals make for the tastiest foods, and we’re committed to sourcing all of our dairy products from the best-treated animals you can find. Anything else would be providing you with less than the best possible cheeses, kinds of milk, creams, and yoghurts. That simply wouldn’t do": "कृषिरत्न मिल्क  वर आमचा विश्वास आहे की सर्वात आनंदी प्राणी सर्वात चविष्ट पदार्थ बनवतात आणि आम्ही आमच्या सर्व दुग्धजन्य पदार्थांचे उत्पादन तुम्हाला मिळू शकणाऱ्या सर्वोत्तम-जोपासना  केलेल्या प्राण्यांपासून मिळवण्यासाठी वचनबद्ध आहोत. इतर कोणतीही गोष्ट तुम्हाला शक्य तितक्या सर्वोत्तम दर्जाची उत्पादने जसे कि दही,दूध, तूप ,पनीर ,खवा आपल्या सेवेत उपलब्ध.",
      "Address": "पत्ता",
      "Contact us on": "आमच्याशी संपर्क साधा",
      "Write us on": "आम्हाला लिहा",
      "Gat No-66, Bhamboli, Khed, Pune, Maharashtra - 410505": "गट क्रमांक-66, भांबोळी, खेड, पुणे, महाराष्ट्र - 410505",
      "+91-7888160160": "+९१-७८८८१६०१६०",
      "bhamchanramilk@gmail.com": "bhamchanramilk@gmail.com",
      "Home": "मुख्यपृष्ठ",
      "About": "आमच्याबद्दल",
      "Contact": "संपर्क",
      "Products": "उत्पादने",
      "Full Cream Milk": "फुल क्रीम दूध",
      "Ghee": "तूप",
      "Cow Milk": "गायचे दूध",
      "Curd": "दही",
      "Taza Milk": "ताजा दूध",
      "Butter Milk": "ताक",
      "Lassi": "लस्सी",
      "Paneer": "पनीर",
      "+91-7888160160": "+९१-७८८८१६०१६०"
    }
  }
};

const defaultLang = localStorage.getItem('i18nextLng') || 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLang, 
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
