import React from 'react';
import Styles from '../pages/styles.module.css'; 
import { useTranslation } from 'react-i18next';
import Testimonials from '../components/Testimonials';
import 'react-multi-carousel/lib/styles.css';

function Home() {
  const { t } = useTranslation();

  return (
    <div className={Styles.appContainer}>
      <div className={Styles.headerContainer}>
        <img
          className={Styles.headerImage}
          src={require('../asset/Image/bgdairy.jpg')}
          alt="Header"
        />
        <h1 className={Styles.headerTitle}>Krushiratna Milk</h1>
        <p className={Styles.headerSubTitle}>
        Our focus on purity and freshness ensures every product is safe, nutritious, and delicious. From our farm to your table, we pride ourselves on sustainable farming and strict quality control.
            </p>
      </div>

      <div className={Styles.container}>
        <div className={Styles.testimonials}>
          <h2>{t("What our customers say")}</h2>
          <Testimonials/>
        </div>
      </div>
     
    </div>
  );
}

export default Home;
